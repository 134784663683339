<template>
  <div class="rsectionheader my-3">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex justify-space-start align-center">
        <span class="label mr-5">{{ label }}</span>
        <slot name="postlabel" />
      </div>
      <slot name="end" />
    </div>
    <v-divider class="divider mb-0 mt-1" />
  </div>
</template>

<script>
export default {
  name: 'RSectionHeader',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: 700;
  font-size: var(--r-font-size);
  line-height: 20px;
  letter-spacing: -0.02px;
  color: var(--r-dark-grey);
}

.divider {
  border: 0;
  border-bottom: 1px solid var(--r-border-color);
}
</style>
