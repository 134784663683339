<template>
  <div class="rtextfield">
    <label>{{ label }}</label>
    <v-textarea
      v-if="type==='text-area'"
      v-model="newValue"
      :placeholder="placeholder"
      :value="newValue"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :validate-on-blur="validateOnBlur"
      :error-messages="errorMessage"
      :min="min"
      :max="max"
      outlined
      class="mt-1"
    />
    <v-text-field
      v-else
      v-model="newValue"
      :placeholder="placeholder"
      :value="newValue"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :type="type"
      :validate-on-blur="validateOnBlur"
      :error-messages="errorMessage"
      :min="min"
      :max="max"
      :hint="hint"
      persistent-hint
      outlined
      dense
      class="mt-1"
      @keydown.enter.prevent
    />
  </div>
</template>

<script>
export default {
  name: 'RTextField',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    errorMessage: String,
    min: Number,
    max: Number,
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newValue: this.$props.value,
    }
  },
  watch: {
    newValue() {
      this.$emit('change', { value: this.$data.newValue })
    },
    value(newValue) {
      this.newValue = newValue
    },
  },
}
</script>

<style scoped>
/deep/ .v-text-field__details {
  padding-left: 0 !important;
  margin-top: 5px;
}
</style>
