var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rtextfield"
  }, [_c('label', [_vm._v(_vm._s(_vm.label))]), _vm.type === 'text-area' ? _c('v-textarea', {
    staticClass: "mt-1",
    attrs: {
      "placeholder": _vm.placeholder,
      "value": _vm.newValue,
      "rules": _vm.rules,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "validate-on-blur": _vm.validateOnBlur,
      "error-messages": _vm.errorMessage,
      "min": _vm.min,
      "max": _vm.max,
      "outlined": ""
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = $$v;
      },
      expression: "newValue"
    }
  }) : _c('v-text-field', {
    staticClass: "mt-1",
    attrs: {
      "placeholder": _vm.placeholder,
      "value": _vm.newValue,
      "rules": _vm.rules,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "type": _vm.type,
      "validate-on-blur": _vm.validateOnBlur,
      "error-messages": _vm.errorMessage,
      "min": _vm.min,
      "max": _vm.max,
      "hint": _vm.hint,
      "persistent-hint": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = $$v;
      },
      expression: "newValue"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }