<template>
  <div
    class="rsection"
    :class="{ enabletransitions }"
  >
    <r-section-header :label="label">
      <!-- collapsible -->
      <template
        v-if="collapsable"
        v-slot:end
      >
        <v-icon
          data-testid="collapse-button"
          @click="toggleCollapse"
        >
          {{ isCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
        </v-icon>
      </template>
    </r-section-header>

    <div
      ref="contentwrapper"
      :style="{ 'max-height': `${ isCollapsed ? '0px' : cardHeight() }` }"
      :class="{ collapsed: isCollapsed }"
      class="contentwrapper"
    >
      <slot />
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import RSectionHeader from '@/components/library/molecules/RSectionHeader'

export default {
  name: 'RSection',
  components: { RSectionHeader },
  props: {
    label: {
      type: String,
      required: true,
    },
    collapsable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isCollapsed: false,
      enabletransitions: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.enabletransitions = true
    }, 300)
  },
  methods: {
    cardHeight() {
      const { contentwrapper } = this.$refs

      if (!this.collapsable) {
        return null
      }

      return this.getNaturalHeight(contentwrapper)
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    },
    getNaturalHeight(element) {
      if (!element) {
        return '0px'
      }

      // save the current height
      const currentHeight = getComputedStyle(element).height

      // remove height limitation to get the natural height
      element.style.maxHeight = 'none' // eslint-disable-line no-param-reassign

      const { height } = getComputedStyle(element)

      // re-state the current height
      element.style.maxHeight = currentHeight // eslint-disable-line no-param-reassign

      return height
    },
  },
}
</script>

<style scoped>
.contentwrapper.collapsed {
  overflow: hidden;
}
</style>
