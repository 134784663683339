<template>
  <div class="addproject mt-5">
    <r-section :label="$t('projects.createProjects.title')">
      <template v-slot:content>
        <v-form
          ref="form"
          v-model="valid"
          class="mt-5"
        >
          <div>
            <v-alert
              v-if="fetchCreateProjectError"
              dense
              dismissible
              tile
              text
              color="red"
              close-label="clear"
              icon="error"
              type="warning"
              elevation="0"
            >
              <small>{{ fetchCreateProjectError }}</small>
            </v-alert>
          </div>

          <v-row
            class="mt-5"
            no-gutters
          >
            <v-col
              cols="12"
              sm=""
              md="6"
              lg="4"
            >
              <r-text-field
                class="input-text addproject--name"
                :value="name"
                :label="$t('projects.name.label')"
                :placeholder="$t('projects.name.placeholder')"
                :rules="[rules.required]"
                @change="onChangeProjectName"
              />
            </v-col>
          </v-row>
          <v-row
            class="mt-5"
            no-gutters
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <r-text-field
                class="input-text addproject--description"
                :value="description"
                :label="$t('projects.description.label')"
                :placeholder="$t('projects.description.placeholder')"
                type="text-area"
                @change="onChangeProjectDescription"
              />
            </v-col>
          </v-row>
          <v-row
            class="mt-5"
            no-gutters
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <div class="d-flex align-center justify-end">
                <r-button
                  class="mr-2 addproject--cancel"
                  color="normal"
                  :label="$t('global.actions.cancel')"
                  @click="onClickCancel"
                />
                <r-button
                  class="addproject--save"
                  :label="$t('global.actions.save')"
                  :loading="fetchingCreateProject"
                  @click="onClickCreateProject"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </r-section>
  </div>
</template>

<script>
import RTextField from '@/components/library/molecules/RTextField'
import RSection from '@/components/library/molecules/RSection'
import RButton from '@/components/library/atoms/RButton'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddProject',
  components: {
    RTextField,
    RSection,
    RButton,
  },
  data() {
    return {
      valid: false,
      name: '',
      description: '',
      rules: {
        required: (v) => !!v || this.$i18n.t('global.validations.messages.required'),
      },
    }
  },
  computed: {
    ...mapState('projects', [
      'fetchingCreateProject',
      'fetchCreateProjectError',
    ]),
  },
  beforeDestroy() {
    this.resetErrors({ fetchCreateProjectError: '' })
  },
  methods: {
    ...mapActions('projects', [
      'createProject',
      'resetErrors',
    ]),
    onChangeProjectName({ value: name }) {
      this.name = name
    },
    onChangeProjectDescription({ value: description }) {
      this.description = description
    },
    onClickCreateProject(e) {
      e.preventDefault()

      if (!this.$refs.form.validate() || this.fetchingCreateProject) {
        return
      }

      const project = {
        name: this.name,
        description: this.description,
      }

      this.createProject({ project }).then(() => {
        if (!this.fetchCreateProjectError) {
          this.$router.push('/data')
        }
      })
    },
    onClickCancel(e) {
      e.preventDefault()

      this.$router.push('/data')
    },
  },
}
</script>

<style scoped>
.rselect {
  width: 200px;
}

</style>
