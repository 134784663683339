var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rsectionheader my-3"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('div', {
    staticClass: "d-flex justify-space-start align-center"
  }, [_c('span', {
    staticClass: "label mr-5"
  }, [_vm._v(_vm._s(_vm.label))]), _vm._t("postlabel")], 2), _vm._t("end")], 2), _c('v-divider', {
    staticClass: "divider mb-0 mt-1"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }